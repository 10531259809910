/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from '../../../i18n'

import {ChainCodeVersion} from "../constants"

export const NetworkStatus = {
  created: {code: 'created', text: i18n.t('common.netStatus[0]')},
  started: {code: 'started', text: i18n.t('common.netStatus[1]')},
}

export const NetworkUpdateStatus = {
  draft: {code: 'draft', text: i18n.t('common.updateStatus[0]')},
  requested: {code: 'requested', text: i18n.t('common.updateStatus[1]')},
  error: {code: 'error', text: i18n.t('common.updateStatus[2]')},
  success: {code: 'success', text: i18n.t('common.updateStatus[3]')},
}

export const NetworkStatuses = {}
NetworkStatuses[NetworkStatus.created.code] = {}
NetworkStatuses[NetworkStatus.created.code][NetworkUpdateStatus.draft.code] = {text: i18n.t('common.updateStatus[0]'), style: 'primary'}
NetworkStatuses[NetworkStatus.created.code][NetworkUpdateStatus.requested.code] = {text: i18n.t('common.updateStatus[1]'), style: 'warning'}
NetworkStatuses[NetworkStatus.created.code][NetworkUpdateStatus.error.code] = {text: i18n.t('common.updateStatus[2]'), style: 'danger'}
NetworkStatuses[NetworkStatus.created.code][NetworkUpdateStatus.success.code] = {text: i18n.t('common.updateStatus[3]'), style: 'success'}
NetworkStatuses[NetworkStatus.started.code] = {}
NetworkStatuses[NetworkStatus.started.code][NetworkUpdateStatus.draft.code] = {text: i18n.t('common.updateStatusStarted[0]'), style: 'primary'}
NetworkStatuses[NetworkStatus.started.code][NetworkUpdateStatus.requested.code] = {text: i18n.t('common.updateStatusStarted[1]'), style: 'warning'}
NetworkStatuses[NetworkStatus.started.code][NetworkUpdateStatus.error.code] = {text: i18n.t('common.updateStatusStarted[2]'), style: 'danger'}
NetworkStatuses[NetworkStatus.started.code][NetworkUpdateStatus.success.code] = {text: i18n.t('common.updateStatusStarted[3]'), style: 'success'}

export const BlockchainConfigUpdateType = {
  EXCLUSIVE: {code: 'EXCLUSIVE', text: i18n.t('common.bchSelect[0]')},
  OR: {code: 'OR', text: i18n.t('common.bchSelect[1]')},
  AND: {code: 'AND', text: i18n.t('common.bchSelect[2]')},
  MAJORITY: {code: 'MAJORITY', text: i18n.t('common.bchSelect[3]')}
}

export const NetworkTypes = ChainCodeVersion

export const NetworkOrgTypes = {
  orderer_organizations: 'orderer_organizations',
  consortium: 'consortium',
}

export const ZERO_TRANZACTION_ID = '00000000-0000-0000-0000-000000000000'