<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div v-if="!loading">
    <div
      class="tooltip-wrapper"
      v-if="renderComponent"
      v-c-tooltip="{
        content: tooltipAddServer,
        placement: 'top',
        visible: tooltipAddServer,
      }"
    >
      <CButton
        class="ml-2 mb-3"
        color="dark"
        variant="outline"
        @click="isShowModal = true"
        :disabled="disabledAddServer"
      >
        <CIcon name="cil-plus" /> {{ $t("service.addServer") }}
      </CButton>
    </div>

    <CModal :title="$t('service.addNewSegment')" :show.sync="isShowModal">
      <CInput
        v-model="service.hostname"
        horizontal
        :label="$t('service.address[0]')"
        :placeholder="$t('service.address[1]')"
        class="mb-1"
        :isValid="isValid.hostname"
        :tooltipFeedback="false"
        :invalidFeedback="errorMessageForHostname"
        @change="$v.service.hostname.$touch()"
      ></CInput>

      <CInput
        v-model="service.port"
        horizontal
        :label="$t('service.port') + '*'"
        :placeholder="$t('service.portPlaceholder')"
        class="mb-1"
        :isValid="isValid.port"
        :tooltipFeedback="false"
        :invalidFeedback="errorMessageForPort"
        @change="$v.service.port.$touch()"
      ></CInput>

      <div slot="footer" class="footer-buttons">
        <CButton type="button" class="btn btn-secondary" @click="closeModal">
          {{ $t("common.cancel") }}
        </CButton>
        <CButton
          type="button"
          class="btn btn-primary"
          @click="createService"
          :disabled="!isFormValid"
        >
          {{ $t("common.create") }}
        </CButton>
      </div>
    </CModal>
  </div>
  <div v-else>
    {{ `${$t("common.loading")}...` }}
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";

import utilMessages from "@/utils/messages";
import messages from "../messages";

import { isValidHostname, isValidPort } from "@/utils/validators";
import { mapActions, mapGetters } from "vuex";
import { NetworkUpdateStatus } from "../networks/constants";

export default {
  name: "AddServer",
  components: {
    BFormInput,
  },

  props: {},

  data() {
    return {
      validationMessages: messages.validation,
      netUpdateStatus: NetworkUpdateStatus,
      renderComponent: true,

      service: {
        hostname: null,
        port: null,
      },
      isShowModal: false,
      loading: false,
      currentNetwork: null,
    };
  },

  validations: {
    service: {
      hostname: {
        required,
        isValidHostname,
      },
      port: {
        required,
        isValidPort,
      },
    },
  },

  async mounted() {
    try {
      this.forceRerender();
      this.loading = true;
      await this.fetchNetworks();
      this.currentNetwork = this.getNetwork(this.getUser.blockchain_net_id);
    } finally {
      this.loading = false;
    }
  },

  computed: {
    ...mapGetters(["getUser", "getNetwork"]),
    isValid() {
      let hostname, port;
      if (this.$v.service.hostname.$dirty) {
        hostname = !this.$v.service.hostname.$invalid;
      }

      if (this.$v.service.port.$dirty) {
        port = !this.$v.service.port.$invalid;
      }

      return {
        hostname: hostname,
        port: port,
      };
    },
    isFormValid() {
      return !this.$v.service.$invalid;
    },
    errorMessageForHostname() {
      if (!this.$v.service.hostname.required) {
        return this.validationMessages.required;
      } else if (!this.$v.service.hostname.isValidHostname) {
        return this.validationMessages.hostname;
      }
      return null;
    },
    errorMessageForPort() {
      if (!this.$v.service.port.required) {
        return this.validationMessages.required;
      } else if (!this.$v.service.port.isValidHostname) {
        return this.validationMessages.port;
      }
      return null;
    },
    tooltipAddServer() {
      return this.disabledAddServer
        ? this.$t("service.bchInChange")
        : this.$t("service.serverCreating");
    },
    disabledAddServer() {
      if (!this.currentNetwork) {
        return false;
      }

      return (
        this.currentNetwork.update_status !== this.netUpdateStatus.success.code
      );
    },
  },
  methods: {
    ...mapActions(["fetchNetworks"]),
    async createService() {
      try {
        await this.$store.dispatch("createServer", this.service);
        this.closeModal();
        this.$emit("onSuccess", {});
      } catch (err) {
        this.closeModal();
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    closeModal() {
      this.isShowModal = false;
      this.service = {
        hostname: null,
        port: null,
      };
      this.$v.$reset();
    },

    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.tooltip-wrapper {
  width: fit-content;
}
</style>
