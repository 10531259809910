/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/



// Main constants
export const ChainCodeVersion = {
  GOSMART10: {code: 'GOSMART1.0', text: 'ViPNet CryptoSmart', src:"/img/networks/gosmart.png"},
  HLF14: {code: 'HLF1.4', text: 'Hyperledger Fabric 1.4 (ECDSA)', src:"/img/networks/hlf.png"},
  HLF20: {code: 'HLF2.0', text: 'Hyperledger Fabric 2.0 (ECDSA)', src:"/img/networks/hlf.png"},
  HLF22: {code: 'HLF2.2', text: 'Hyperledger Fabric 2.2 (ECDSA)', src:"/img/networks/hlf.png"},
}