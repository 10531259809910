<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div class="ml-2 mt-2">
    <CCard
      class="mb-2"
      v-for="(item, i) in list"
      :accentColor="accordion === i ? 'primary' : ''"
      :key="item.id"
    >
      <CCardHeader
        class="border-bottom-0 collapse_item_header"
        @click="accordion = accordion === i ? false : i"
      >
        <template v-if="isKnown">
          <strong>{{ item.hostname }}:{{ item.port }}</strong
          >&nbsp;&nbsp;
          <span @click.stop="getTopicId(item.id)">
            <fa-icon
              v-if="item.update_status"
              :icon="isWsOpen(item) ? 'sync' : 'circle'"
              size="1x"
              :style="statusColor(item.update_status, item.warnings)"
              :class="isWsOpen(item) ? 'animated' : ''"
            />
          </span>
          <span
            class="p-2"
            @click.stop.prevent="updateItem(i)"
            v-if="item.update_status === updateStatuses.error.code"
          >
            <fa-icon icon="sync" size="1x" />
          </span>
          <div class="card-header-actions">
            <CButton
              @click.stop.prevent="deleteItem(i)"
              size="sm"
              class="p-0 m-0 mr-forTrash"
            >
              <CIcon name="cil-trash" size="sm" />
            </CButton>
            <CButton size="sm" class="p-0 m-0 self-align-right">
              <!--                  <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />-->
              <!--                  <fa-icon icon="angle-down" size="lg" v-if="accordion !== i" />-->
            </CButton>
          </div>
        </template>
        <template v-else>
          <strong>{{ $t("service.unbind") }}</strong
          >&nbsp;&nbsp;
          <div class="card-header-actions">
            <CButton size="sm" class="p-0 m-0">
              <!--                  <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />-->
              <!--                  <fa-icon icon="angle-down" size="lg" v-if="accordion !== i" />-->
            </CButton>
          </div>
        </template>
      </CCardHeader>

      <CCollapse :show="true">
        <CCardBody class="border-top">
          <CDataTable
            v-if="isEndPointsExist(item)"
            striped
            :items="item.endpoints"
            :fields="fieldsForTable"
            :items-per-page="100"
            @row-clicked="itemClicked"
            hover
            :clickable-rows="isKnown"
            responsive
          >
            <template #id="{ item }">
              <td class="td-align-right">
                <CButton
                  @click.stop.prevent="deleteEndpoint(item.id)"
                  size="sm"
                  class="p-0 m-0"
                >
                  <CIcon name="cil-trash" size="sm" />
                </CButton>
              </td>
            </template>
          </CDataTable>
          <div v-else>{{ $t("common.noData") }}</div>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import utilMessages from "../../../utils/messages";
import { ServiceUpdateStatus } from "./constants";

export default {
  name: "Accordion",
  data() {
    return {
      accordion: false,
      fieldsForTable: [
        { key: "hostname", label: "Hostname", _classes: "font-weight-bold" },
        { key: "port", label: this.$t("service.port") },
        { key: "type", label: this.$t("service.type") },
        { key: "blockchain_net_name", label: this.$t("service.bchNetwork") },
        { key: "id", label: "" },
      ],
      updateStatuses: ServiceUpdateStatus,
    };
  },
  props: {
    list: Array,
    isKnown: Boolean,
  },
  methods: {
    addItem() {
      this.$emit.addItem();
    },
    statusColor(status, warnings) {
      if (status) {
        if (warnings) {
          return { color: "orange" };
        }
        const color = this.updateStatuses[status].style;
        return { color };
      }
      return "";
    },
    isWsOpen(item) {
      return (
        item.update_status === this.updateStatuses.requested.code ||
        item.update_status === this.updateStatuses.draft.code
      );
    },
    isEndPointsExist(item) {
      const endpointArr = item.endpoints || [];
      return endpointArr.length > 0;
    },
    async deleteItem(i) {
      const serverId = this.list[i].id;
      try {
        await this.$store.dispatch("deleteServer", serverId);
        this.$toast.success(this.$t("service.serverDeleted"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async updateItem(i) {
      const server = this.list[i];
      const serverId = this.list[i].id;
      try {
        await this.$store.dispatch("updateServer", { serverId, data: server });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async deleteEndpoint(endpointId) {
      try {
        await this.$store.dispatch("deleteEndpointFromServer", endpointId);
        this.$toast.success(this.$t("service.endpointDeleted"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getTopicId(serverId) {
      const server = this.list.find((server) => server.id === serverId);
      if (server.topicId) {
        this.toTopic(server.topicId);
      } else {
        try {
          const resId = await this.$store.dispatch(
            "getTopicByIdForService",
            serverId
          );
          this.toTopic(resId);
        } catch (err) {
          this.$toast.error(utilMessages.errMessage(err));
        }
      }
    },
    toTopic(topicId) {
      this.$router.push({ path: `/topics/${topicId}` });
    },
    itemClicked(item) {
      this.isKnown && this.$router.push({ path: `/endpoints/${item.id}` });
    },
    getStatus(item) {
      const { status } = item;
      if (status === "attached") return this.$t("service.connected");
      if (status === "detached") return this.$t("service.availableNot");
      return status;
    },
  },
};
</script>

<style>
.collapse_item_header {
  cursor: pointer;
}
.collapse_item_header.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px)
    calc(0.25rem - 1px);
}
.animated {
  animation: spin 2s linear infinite;
}
.td-align-right {
  text-align: right;
  padding-right: 32px !important;
}
.mr-forTrash {
  margin-right: 34px !important;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
