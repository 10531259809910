<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm>
        <CCard>
          <CCardHeader>
            <div class="mb-4">
              <strong>{{ $t("service.services") }}</strong>
            </div>
            <Accordion :list="allKnownServices" :isKnown="true" />
            <AddServer @onSuccess="getServices" />
            <Accordion :list="allUnknownServices" />
          </CCardHeader>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import utilMessages from "../../../utils/messages";
import Accordion from "./Accordion.vue";
import AddServer from "./AddServer.vue";

export default {
  components: { Accordion, AddServer },
  name: "ServerList",
  data() {
    return {};
  },

  async mounted() {
    await this.getServices();
  },

  updated() {
    this.allServices.forEach((server) => {
      if (server.errorMessage != null) {
        this.$toast.error(server.errorMessage);
        this.deleteErrorMessageFromServer(server.id);
      }
    });
  },

  computed: {
    ...mapGetters(["allServices"]),

    allKnownServices() {
      return this.allServices.filter((item) => item.id);
    },

    allUnknownServices() {
      return this.allServices.filter((item) => !item.id);
    },
  },

  methods: {
    ...mapMutations(["deleteErrorMessageFromServer"]),
    async getServices() {
      try {
        await this.$store.dispatch("fetchServices");
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
  },
};
</script>
